import {useCallback, useState} from 'react'

export const useToggle = (initialValue = false) => {
    const [state, setState] = useState(initialValue)

    const toggle = useCallback(
        (value: boolean | ((prev: boolean) => boolean) | any | undefined = undefined) => setState(prev => typeof value === 'function' || typeof value === 'boolean'
            ? typeof value === 'function'
                ? value(prev)
                : value
            : !prev),
        [],
    )
    const toggleOpen = useCallback(() => setState(true), [])
    const toggleClose = useCallback(() => setState(false), [])
    return {open: state, setState, toggle, toggleOpen, toggleClose}
}
